<template>
  <!-- Work in progress - this component will probably be changed entirely -->
  <v-card class="segmented" flat>
    <v-card-title>
      <v-row>
        <v-col cols="12">
          <h5>{{ $t('reporting.title') }}</h5>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-list flat color="white">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title><h3>Status Reporting</h3></v-list-item-title>

            <v-list-item-subtitle>{{
              $t('reporting.statusReportInfo')
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="isProcessTypeAvailable(processTypes.MAFEG)"
          :to="{
            name: 'status-report',
            params: {
              reportType: 1,
              processType: processTypes.MAFEG
            }
          }"
        >
          <v-list-item-content>
            <v-list-item-title>Report: MAFEG Status</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="isProcessTypeAvailable(processTypes.EHG)"
          :to="{
            name: 'status-report',
            params: {
              reportType: 1,
              processType: processTypes.EHG
            }
          }"
        >
          <v-list-item-content>
            <v-list-item-title>Report: EHG Status</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="isProcessTypeAvailable(processTypes.PDP)"
          :to="{
            name: 'pdp-report',
            params: { reportType: 2 }
          }"
        >
          <v-list-item-content>
            <v-list-item-title>Report: PDP Status</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="isProcessTypeAvailable(processTypes.PDC)"
          :to="{
            name: 'status-report',
            params: {
              reportType: 1,
              processType: processTypes.PDC
            }
          }"
        >
          <v-list-item-content>
            <v-list-item-title>Report: PDC Status</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="isProcessTypeAvailable(processTypes.EDC)"
          :to="{
            name: 'status-report',
            params: {
              reportType: 1,
              processType: processTypes.EDC
            }
          }"
        >
          <v-list-item-content>
            <v-list-item-title>Report: EDC Status</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          disabled
          :to="{
            name: 'pdp-report',
            params: { reportType: 999 }
          }"
        >
          <v-list-item-content v-if="false">
            <v-list-item-title>Report: Beförderungsprozess</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list flat color="white mt-4">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              ><h3>Erweitertes Reporting</h3></v-list-item-title
            >

            <v-list-item-subtitle>{{
              $t('reporting.advancedReportInfo')
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{
            name: 'career-development-report'
          }"
        >
          <v-list-item-content>
            <v-list-item-title
              >Report: Angestrebte Entwicklung</v-list-item-title
            >

            <v-list-item-subtitle>
              In diesem Report findest du eine Übersicht über die Eingaben
              deiner direkten und indirekten Mitarbeitenden in Bezug auf die
              angestrebte Entwicklung (hierarchische / laterale Entwicklung) aus
              den freigegebenen MAFEG- und / oder EHG-Dokumentationen.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{
            name: 'development-measures-report',
            params: {
              reportType: 3,
              processType: processTypes.MAFEG
            }
          }"
        >
          <v-list-item-content>
            <v-list-item-title>Report: Entwicklungsmaßnahmen</v-list-item-title>
            <v-list-item-subtitle>
              In diesem Report findest du eine Übersicht über die Eingaben
              deiner direkten und indirekten Mitarbeitenden in Bezug auf die
              gewünschten Entwicklungsmaßnahmen (z. B. Trainings, WBTs,
              Skill-Workshops) aus der freigegebenen MAFEG-Dokumentation.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{
            name: 'development-measures-report',
            params: {
              reportType: 5,
              processType: processTypes.MAFEG
            }
          }"
        >
          <v-list-item-content>
            <v-list-item-title
              >Report: Performance in der Expertenrolle</v-list-item-title
            >

            <v-list-item-subtitle>
              <em
                >Nur relevant, wenn du direkte oder indirekte Führungskraft von
                Experten und Expertinnen bist (ausgenommen C&S Experten)</em
              >
              <br />
              In diesem Report findest du eine Übersicht über die Eingaben
              deiner direkten und indirekten Experten und Expertinnen in Bezug
              auf ihre Performance in der Expertenrolle aus der freigegebenen
              MAFEG-Dokumentation.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{
            name: 'development-measures-report',
            params: {
              reportType: 6,
              processType: processTypes.MAFEG
            }
          }"
        >
          <v-list-item-content>
            <v-list-item-title
              >Report: Auswertung der Testrolle</v-list-item-title
            >

            <v-list-item-subtitle>
              <em
                >Nur relevant, wenn du direkte oder indirekte Führungskraft von
                Mitarbeitenden mit einer offiziellen Testrolle bist.</em
              >
              <br />
              In diesem Report findest du eine Übersicht über die Eingaben
              deiner direkten und indirekten Mitarbeitenden in Bezug auf ihre
              Testrolle aus der freigegebenen MAFEG-Dokumentation.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{
            name: 'development-measures-report',
            params: {
              reportType: 7,
              processType: processTypes.MAFEG
            }
          }"
        >
          <v-list-item-content>
            <v-list-item-title
              >Report: Auswertung der Sonderrolle</v-list-item-title
            >
            <v-list-item-subtitle>
              <em
                >Nur relevant, wenn Mitarbeitende eine Sonderrolle ausüben. Eine
                Sonderrolle kann von Kolleginnen und Kollegen in der Position
                Recruiting Specialist, Care Specialist oder Process Coordinator
                übernommen werden.</em
              >
              <br />
              In diesem Report findest du eine Übersicht über die Eingaben
              deiner direkten und indirekten Mitarbeitenden in Bezug auf ihre
              ausgeübte Sonderrolle aus der freigegebenen MAFEG-Dokumentation.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { feedbackEnums } from '@/enums/feedbacks.js';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    }),

    processTypes() {
      return feedbackEnums.processTypes;
    }
  },

  methods: {
    isProcessTypeAvailable(processType) {
      if (this.currentUser.isAdmin) {
        return true;
      }

      return this.currentUser.processTypesAsSupervisor.includes(processType);
    }
  }
};
</script>

<style scoped>
.v-list-item__subtitle {
  white-space: normal;
}
</style>
